var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"my-4"},[_c('Filtros',{attrs:{"P_titulo":"","P_status":"","P_statusArray":_vm.status,"P_prioridade":"","P_clienteServico":"","P_dataColuna":"","P_dataFrom":"","P_dataTo":""}})],1),_c('v-card',{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',{staticClass:"accent text-h5 font-weight-bold pa-3"},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-toolbox-outline")]),_vm._v(" Atividades ")],1),_c('v-divider'),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","clearable":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.listaAtividades.length},on:{"click":_vm.gerarpdf}},[_vm._v(" relatório ")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"items":_vm.listaAtividades,"search":_vm.search,"loading":_vm.loading,"items-per-page":10,"sort-desc":true},on:{"click:row":_vm.goToAtividadeCliente},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [_c('v-icon',{attrs:{"color":item.status == 0
                ? 'grey'
                : item.status == 1
                ? 'blue-grey'
                : item.status == 2
                ? 'yellow darken-4'
                : item.status == 3
                ? 'green'
                : item.status == 4
                ? 'purple'
                : item.status == 5
                ? 'green darken-4'
                : item.status == 6
                ? 'deep-purple accent-4'
                : item.status == -1
                ? 'red'
                : 'light-green',"small":"","left":""}},[_vm._v("mdi-square-rounded")]),_vm._v(_vm._s(_vm._f("statusAtividade")(item.status,item.entrega))+" ")]}},{key:"item.entrega",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.entrega,"dd/MM/yyyy"))+" ")]}},{key:"item.inicio",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.inicio,"dd/MM/yyyy"))+" ")]}},{key:"item.prioridade",fn:function({ item }){return [(item.prioridade === 1)?_c('div',[_c('v-icon',{attrs:{"color":"prioridade_baixa"}},[_vm._v(" mdi-flag ")]),_vm._v(" Baixa ")],1):_vm._e(),(item.prioridade === 2)?_c('div',[_c('v-icon',{attrs:{"color":"prioridade_normal"}},[_vm._v(" mdi-flag ")]),_vm._v(" Normal ")],1):_vm._e(),(item.prioridade === 3)?_c('div',[_c('v-icon',{attrs:{"color":"prioridade_urgente"}},[_vm._v(" mdi-flag ")]),_vm._v(" Urgente ")],1):_vm._e(),(item.prioridade === 4)?_c('div',[_c('v-icon',{attrs:{"color":"prioridade_emergencia"}},[_vm._v(" mdi-flag ")]),_vm._v(" Emergência ")],1):_vm._e()]}}])})],1),_c('v-divider')],1),(_vm.dialogAtividadeCliente)?_c('ViewAtividadeCliente',{attrs:{"dialogAtividadeCliente":_vm.dialogAtividadeCliente,"item":_vm.atividade},on:{"update:dialogAtividadeCliente":function($event){_vm.dialogAtividadeCliente=$event},"update:dialog-atividade-cliente":function($event){_vm.dialogAtividadeCliente=$event},"fetch-atividade":_vm.getAtividades}}):_vm._e(),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":"LISTA DE ATIVIDADES"},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }